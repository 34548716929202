<template>
  <v-main>
    <v-container fluid class="py-0">
      <device-map
        @openDevicePictureDialog="openDevicePictureDialog"
      ></device-map>
      <notification-icon-list></notification-icon-list>
      <latest-device-log></latest-device-log>
      <DevicePictureViewDialog
        :dialog="isOpenDevicePictureDialog"
        @close="close"
      />
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'
import { getDevices } from '@/api'
import DeviceMap from '@/components/device-map'
import NotificationIconList from '@/components/notification-icon-list'
import LatestDeviceLog from '@/components/latest-device-log'
import DevicePictureViewDialog from '@/components/device-picture-view-dialog'

export default {
  name: 'HomeIndex',
  components: {
    DeviceMap,
    NotificationIconList,
    LatestDeviceLog,
    DevicePictureViewDialog
  },
  data() {
    return {
      isOpenDevicePictureDialog: false
    }
  },
  async created() {
    if (this.$store.getters.devices.length > 0) {
      return
    }
    let res
    try {
      res = await getDevices()
    } catch (e) {
      console.error(e)
      alert('サーバエラーが発生しました')
      return
    }
    this.setDevices(res.devices)
  },
  methods: {
    ...mapActions(['setDevices']),
    openDevicePictureDialog() {
      this.isOpenDevicePictureDialog = true
    },
    close() {
      this.isOpenDevicePictureDialog = false
    }
  },
  mounted() {
    // ページ遷移後、最上部にスクロールさせてマップと検索ボックスが隠れないようにする
    window.scroll(0, 0)
  }
}
</script>
