<template>
  <v-row class="my-0">
    <v-col class="pa-0">
      <div id="map">
        <v-text-field
          v-model="searchAddressInput"
          type="text"
          hide-details="auto"
          class="search_field"
          append-icon="mdi-magnify"
          @click:append="searchLocation"
          @keydown.enter="searchLocation"
          placeholder="場所を検索"
          solo
        />
        <gmap-map
          ref="gmap"
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 500px"
          :options="{
            disableDefaultUI: false,
            scrollwheel: true,
            styles: mapStyles
          }"
          @click="unsetInfoDeviceId"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoDevice.position"
            :opened="infoDeviceId !== ''"
            @closeclick="unsetInfoDeviceId"
          >
            <div class="card">
              <div class="card-content py-3 px-3">
                <div>ID: {{ infoDevice.id }}</div>
                <div class="title">
                  {{ infoDevice.name }}
                </div>
                <div>
                  <v-btn
                    small
                    plain
                    class="px-0 py-0"
                    @click="$emit('openDevicePictureDialog')"
                  >
                    <v-icon small>mdi-camera</v-icon>
                    <span>センサ設置画像の表示</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </gmap-info-window>
          <div v-for="d in devices" :key="d.id">
            <gmap-marker
              dense
              :position="d.position"
              :icon="selectIcon(d)"
              @click="setInfoDeviceId(d.id)"
            >
            </gmap-marker>
          </div>
        </gmap-map>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as easings from 'vuetify/es5/services/goto/easing-patterns'
import iconPinOn from '@/assets/pin-on.svg'
import iconPinOff from '@/assets/pin-off.svg'
import iconPinAlert from '@/assets/pin-alert.svg'
import iconPinAliveAlert from '@/assets/pin-alive-alert.svg'
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_API_VERSION,
  DEVICE_STATUS_ENABLED
} from '@/constants'
import mapStyle from '@/styles/map-style'

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY,
    v: GOOGLE_MAPS_API_VERSION
  }
})

const maxZoomValue = 18
const searchZoomValue = 11

export default {
  name: 'DeviceMap',
  data() {
    return {
      // map settings
      mapStyles: mapStyle,
      // a default center for the map
      center: { lat: 34.9950044, lng: 135.7364171 },
      infoWinOpen: false,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      zoom: maxZoomValue,
      initialize: true,
      searchAddressInput: ''
    }
  },
  computed: {
    ...mapGetters(['devices', 'infoDevice', 'infoDeviceId', 'reloadMap'])
  },
  watch: {
    devices() {
      if (this.initialize) {
        this.initMap(false)
        this.initialize = false
      }
    },
    reloadmap(isReload) {
      if (isReload) {
        this.initMap(isReload)
        this.completeReloadMap()
      }
    }
  },
  mounted() {
    if (this.devices.length > 0) {
      this.initMap(false)
      this.initialize = false
    }
  },
  methods: {
    ...mapActions(['setInfoDeviceId', 'unsetInfoDeviceId']),
    initMap(isReload) {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new google.maps.LatLngBounds()
        this.devices.map(d => bounds.extend(d.position))
        if (!isReload) {
          map.fitBounds(bounds)
          google.maps.event.addListener(map, 'idle', () => {
            if (map.getZoom() > this.zoom) {
              map.setZoom(this.zoom)
            }
          })
        }
      })
    },
    selectIcon(device) {
      if (device.is_device_alive_alert) {
        return iconPinAliveAlert
      }
      if (device.is_alert) {
        return iconPinAlert
      }
      if (device.device_status === DEVICE_STATUS_ENABLED) {
        return iconPinOn
      }
      return iconPinOff
    },
    // searchAddressInput の内容から検索し、マップを移動させる
    searchLocation(event) {
      // 日本語入力中のEnterは無視する
      if (event.keyCode && event.keyCode !== 13) {
        return
      }
      if (this.searchAddressInput.length === 0) {
        return
      }

      // 検索にマッチした最初の場所にマップを移動させる
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode(
        { address: this.searchAddressInput },
        (results, status) => {
          if (status === 'OK') {
            this.$refs.gmap.$mapObject.setCenter({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            })
            this.$refs.gmap.$mapObject.setZoom(searchZoomValue)
          } else if (status === 'ZERO_RESULTS') {
            alert('検索結果はありませんでした。')
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$search_field_width: 350px;
$margin_x: 10px;

.search_field {
  position: absolute;
  top: 60px;
  margin: 0 $margin_x;
  z-index: 1;
  width: $search_field_width;
}

// スマートフォン表示では「地図｜航空写真」に重なるように位置を移動させ、横幅を調整する
@media (max-width: 480px) {
  .search_field {
    top: 10px;
    width: 200px;
  }
}
</style>
