<template>
  <v-row class="caption justify-center">
    <v-col col="2" class="d-flex justify-center">
      <div>
        <img src="@/assets/pin-on.svg" alt="" />
      </div>
      <div class="body-2">
        動作中 <span class="title">{{ deviceEnabledCount }}</span> 件
      </div>
    </v-col>
    <v-col col="2" class="d-flex justify-center">
      <div>
        <img src="@/assets/pin-off.svg" alt="" />
      </div>
      <div class="body-2">
        停止中 <span class="title">{{ deviceDisabledCount }}</span> 件
      </div>
    </v-col>
    <v-col col="3" class="d-flex justify-center">
      <div>
        <img src="@/assets/pin-alert.svg" alt="" />
      </div>
      <div class="body-2">
        浸水検知中 <span class="title">{{ deviceAlertCount }}</span
        >件
      </div>
    </v-col>
    <v-col col="3" class="d-flex justify-center">
      <div>
        <img src="@/assets/pin-alive-alert.svg" alt="" />
      </div>
      <div class="body-2">
        自己診断異常 <span class="title">{{ deviceAliveAlertCount }}</span
        >件
      </div>
    </v-col>
    <v-col col="3" class="d-flex justify-center">
      <div>
        <v-btn icon @click="doLoad">
          <v-progress-circular
            v-show="loading"
            :color="mainColor"
            :indeterminate="loading"
          ></v-progress-circular>
          <v-icon v-show="!loading" :color="mainColor">mdi-reload</v-icon>
        </v-btn>
      </div>
      <div class="body-2" style="line-height: 2.2rem;">
        再読み込み
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getDevices } from '@/api'
import { MAIN_COLOR } from '@/constants'

const loadDevicesIntervalTime = 30000 // 30秒

export default {
  name: 'NotificationIconList',
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      intervalId: null
    }
  },
  computed: {
    ...mapGetters([
      'deviceEnabledCount',
      'deviceDisabledCount',
      'deviceAlertCount',
      'deviceAliveAlertCount'
    ])
  },
  mounted() {
    console.log('load devices polling start.')
    this.intervalId = setInterval(() => {
      this.doLoad()
    }, loadDevicesIntervalTime)
  },
  beforeDestroy() {
    console.log('load devices polling stop.')
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapActions(['setDevices', 'doReloadMap']),
    async doLoad() {
      if (this.loading) {
        return
      }
      console.log('load devices...')
      this.loading = true
      await this.initDevices()
      this.doReloadMap()
      this.loading = false
    },
    async initDevices() {
      let res
      try {
        res = await getDevices()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      const devices = res.devices
      this.setDevices(devices)
    }
  }
}
</script>
