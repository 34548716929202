<template>
  <v-dialog v-model="dialog" max-width="920" @click:outside="close">
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">センサ名: {{ infoDevice.name }} の設置画像</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <template v-if="pictures.length > 0">
            <v-row>
              <div>{{ pictureNo + 1 }} / {{ pictures.length }} 枚目</div>
              <v-carousel hide-delimiters v-model="pictureNo" height="50vh">
                <template v-for="pic of pictures">
                  <v-carousel-item
                    :key="pic.picture_id"
                    :placeholder="pic.description"
                    height="auto"
                  >
                    <v-sheet height="100%" color="white">
                      <img
                        :src="`${pic.get_url}?v=${timestamp}`"
                        width="100%"
                        height="100%"
                        style="object-fit: contain;"
                      />
                    </v-sheet>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-row>
            <v-row>
              <v-col>
                <!-- carousel の中に入れると画像の比率が少しおかしくなるため外に出している -->
                {{ pictures[pictureNo].description }}
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              登録された画像がありません。
            </v-row>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDevicePictures } from '@/api/index'

export default {
  name: 'DevicePictureViewDialog',
  props: {
    dialog: Boolean
  },
  data() {
    return {
      loading: false,
      pictureNo: 0,
      pictures: []
    }
  },
  async created() {
    this.loading = true
    await this.initDevicePicture()
    this.loading = false
  },
  computed: {
    ...mapGetters(['infoDevice']),
    timestamp() {
      return new Date().getTime()
    }
  },
  methods: {
    async initDevicePicture() {
      if (!this.dialog) {
        return
      }
      if (this.infoDevice.id === '') {
        return
      }
      console.log('initDevicePicture')
      try {
        const res = await getDevicePictures(this.infoDevice.id)
        this.pictures = res.pictures
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
    },
    close() {
      this.pictureNo = 0
      this.pictures = []
      this.$emit('close')
    }
  },
  watch: {
    async dialog() {
      if (!this.dialog) {
        return
      }
      this.loading = true
      await this.initDevicePicture()
      this.loading = false
    }
  }
}
</script>
