<template>
  <v-row>
    <v-col>
      <v-card class="mx-auto">
        <v-card-title
          class="white--text title body-1 pa-2"
          :style="`background-color: ${mainColor}`"
          dense
        >
          <v-icon dense left class="white--text">mdi-post</v-icon
          >最近の浸水検知ログ センサID: {{ diviceIdDisplay }}
        </v-card-title>
        <v-container
          fluid
          id="scroll-target"
          style="max-height: 200px"
          class="overflow-y-auto py-0"
        >
          <v-data-table
            :headers="deviceAlertHeaders"
            :items="deviceAlertLogs"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:[`item.timestamp`]="{ item }">
              {{ item.timestamp | moment }}
            </template>
            <template v-slot:[`item.send_type`]="{ item }">
              {{ item.send_type | sendTypeLabel }}
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { getDeviceAlertLog } from '@/api'
import { MAIN_COLOR, NOTIFICATION_STATUS_LIST } from '@/constants'

export default {
  name: 'LatestDeviceLog',
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      deviceAlertLogs: [],
      deviceAlertHeaders: [
        {
          text: 'アラート日時',
          align: 'start',
          value: 'timestamp'
        },
        {
          text: '送信件数',
          sortable: false,
          value: 'send_count'
        },
        {
          text: '通知タイプ',
          align: 'start',
          value: 'send_type'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['infoDeviceId']),
    diviceIdDisplay() {
      return this.infoDeviceId === '' ? '未選択' : this.infoDeviceId
    }
  },
  watch: {
    async infoDeviceId(id) {
      if (id === '') {
        this.deviceAlertLogs = []
        return
      }
      this.loading = true
      const logs = await this.initDeviceAlertLogs()
      this.deviceAlertLogs = logs.splice(0, 5)
      this.loading = false
    }
  },
  methods: {
    async initDeviceAlertLogs() {
      let res
      try {
        res = await getDeviceAlertLog(this.infoDeviceId)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      return res.device_alert_logs
    }
  },
  filters: {
    moment(timestamp) {
      return moment(timestamp).format('YYYY/MM/DD HH:mm:ss')
    },
    sendTypeLabel(value) {
      const target = NOTIFICATION_STATUS_LIST.find(i => i.value === value)
      if (target === undefined) {
        return ''
      }
      return target.label
    }
  }
}
</script>
